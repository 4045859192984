const en={
    common:{
        yes:"Yes",
        no:"No",
        verfRequirement:"Verification Requirement",
        baseInfo:"Basic information of verified factory",
        clientNameLocal:"Registered Company Name (local language)",
        clientNameLocalError:"Please enter the company name",
        clientNameEn:"Registered Company Name (English)",
        addressLocal:"Company Address (local language)",
        addressLocalError:"Please enter the address",
        addressEn:"Company Address (English)",
        industry:"Industry",
        businessType:"Company type",
        pleaseDetail:"Please enter",
        contactInfo:"Contact Information",
        contact:"Contact Person",
        contactError:"Please enter the contact person",
        mobile:"Telephone",
        mobileError:"Please enter the contact number",
        email:"E-mail Address",
        emailError:"Please enter the E-mail Address",
        countryError:"Please select country",
        taxCodeError:"Please enter the tax code",
        title:"Position",
        titleError:"Please enter the position of contact person",
        submit:"Submit",
        hotline:"Hotline",
        moreService:"More service",
        successMsg:"Well received the application with many thanks. We will check it and contact with you as soon as possible. Service Contact Number: +86 13662650451",
        view:"View",
        selectFile:"Select File",        
        verificationDate:"Verification Date",
        pleaseSelectFile:"Please select File",
        download:"Download",
        delete:"Delete",
        uploadFile:"Upload file",
        createDate:"Create Date",
        updateDate:"Update Date",
        ok:"Ok",
        cancel:"Cancel",
        areyousure:"Are you sure?",
        contactDetails:"Contact Details",
        assignCompleted:"Assign Completed",
        taxCode:"Tax Code",
        country:"Country",
        province:"Province",
        station:"Nearby Transportation (high speed rail station, airport, etc)",
        requiredError:"This is a required field, please enter"        
    },
    generalService:{
        title:"General Service",
        serviceInfo:"Service Information",
        serviceType:"Service Type",
        serviceDate:"Expected Service Date",
        serviceDateError:"Please enter the Expected service Date",  
    },    
    fem:{
        formName:"Higg FEM Verification Application Form",
        type:"Verification Type",
        typeFoundation:"Higg Foundation - No Scoring, Verify the data and records in last 12 months",
        typeFEM:"Higg FEM - With scoring, verify the data and records in last calendar year",
        self:"Verification Level",
        envManagementSystem:"Environmental Management System",
        energyGreenhouseGases:"Energy Use and GHG Emission",
        waterResources:"Water Use",
        wastewater:"Wastewater",
        exhaustEmissions:"Emissions to Air",
        waste:"Waste Management",
        chemicalManagement:"Chemical Management",
        experience:"Verification Experience",
        experience1:"First",
        experience2:"Secondary",
        experience3:"Third Time and above",
        model:"Verification Mode",
        model1:"Onsite Verification",
        model2:"Offsite Verification(*Please check with your client, advance agreement is required)",
        selfassessment:"Is the self-assessment posted",
        promoteBrand:"Brands",
        auditDate:"Expected Verification Date",
        auditDateError:"Please enter the Expected verification Date",
        productType:"Product type and annual shipment volume",
        technology:"Main product process (Please provide production process flow instead if applicable)",
        chemical:"Does the factory use chemical in production processes? Please specify if applicable",
        wwt:"Does the factory have wastewater treatment plant?",
        coverarea:"Factory size (floor area)",
        coverareaError:"Please enter the factory size",
        employee:"NO. of employees",
        employeeError:"Please enter the number",
        esc:"Environmental management system certificates, please specify"
    },
    slcp:{
        formName:"SLCP Verification Application Form",
        type:"Verification Step",
        experience:"Verification Experience",
        experience1:"First Time",
        experience2:"Annual Time",
        experience3:"Follow-up",
        selfassessment:"Does the facility submit the self-assessment? ",
        postDate:"Submit Date",
        ahSystem:"AH platform selection",
        other:"Other",
        promoteBrand:"Brand",
        announcementType:"Verification Announcement",
        announcementType1:"Announced",
        announcementType2:"Unannounced",
        announcementType3:"Semi-Announced with 10-day window",
        auditDate:"Expected Verification Date",
        auditDateError:"Please enter the Expected verification Date",
        model:"Verification Type",
        model1:"Onsite Verification",
        model2:"Full Virtual Verification",
        model3:"Virtual Verification+ Onsite Verification",
        apsca:"Is APSCA verifier requested?",
        employee:"workers",
        employeeError:"Please enter the number of workers",
        manager:"supervisors",
        managerError:"Please enter the number of supervisors",
        csr:"CSR management system certificates (e.g.: BSCI, WRAP, ISO and etc.)",
        serviceProvider:"Onsite Service Providers (e.g. security, janitor, cleaner)",
        productionBuildings:"Numbers of building-production",
        dormitories:"Numbers of buildings-Dormitory",
        warehouses:"Numbers of buildings-Warehouse",
        otherBuildings:"Numbers of buildings-Other",
        productType:"Main product type",
        technology:"Main production processes",
        times:"Times",
        tipWorker:"Kind reminder: Please ensure that the reported number of people is accurate based on the definition of workers",
        tipManager:"Kind reminder: Please ensure that the number of supervisors reported is accurate",        
    },
    application_confirm:{
        applicationForm:"Application form",
        applicationFormUpload:"Application Confirmation",
        notExist:"Application form does not exist",
        error:"Application form exceptions",
        success:"Application has been submitted, we will provide quotations as soon as possible, service hotline: +86 13662650451",
        submitError:"Application submission exception, please contact us: +86 13662650451",
        pleaseSelectfile:"Please select the signed and stamped application form"
    },
    confirm_letter:{
        confirmLetter:"Confirmation Letter Return Receipt",
        notExist:"Service order does not exist",
        error:"Service order exceptions",
        success:"Scheduling has been confirmed, Service hotline: +86 13662650451",
        submitError:"To submit an exception, please contact us: +86 13662650451",
    },
    verfDocument:{
        verfDocument:"Verification Documents",
        pre:"Pre-review Documents",
        other:"Other Documents",
        file:"File",
        updateTime:"Update time",
        size:"Size",
        status:"Status",
        comments:"Comments",
        preTitle:"Pre-review document list",
        pre1:"Energy: Energy consumption records of past three years(2021-2024), Energy/Fuel purchasing invoices or meter reading records (2021-2024).",
        pre2:"Water: Water sources list and water consumption records of past three years (2021-2024), water purchasing invoices or meter reading records (2021-2024).",
        pre3:"Wastewater: Industrial wastewater and/or domestic wastewater quantity tracking records(2024), Invoices or meter reading records(2024), estimating methods if applicable.",
        pre4:"Air Emissions: tracking list of all kinds of air emissions(2024), Invoices or meter reading records(2024), estimating methods if applicable.",
        pre5:"Waste: Non-hazardous waste generation tracking list(2024), hazardous waste generation tracking list(2024), weight reading records/invoices/disposal records(2024), estimating records if applicable.",
    },
    traningSurvey:{
        title:"Sustainable Training Questionaire",
        question1:"Question 1: Topic of Seminar",
        question2:"Question 2: The date you join the seminar",
        question3:"Question 3: Could the content of the seminar answer your questions about the project in your company?",
        question4:"Question 4: Could the content of the seminar provide the guideline to implement your job in company?",
        question5:"Question 5: Was this seminar suitable and inspired to you about the awareness and implementation the environmental management?",
        question6:"Question 6: Did the training material have the tighten structure and easily to use?",
        question7:"Question 7: Did the trainer present in logically, clearly and concenstration?",
        question8:"Question 8: Did the presenter combine many cases and practice cases?",
        question9:"Question 9: Did the presenter grasp the process of the seminar?",
        question10:"Question 10: Please provide your evaluation about the attitude, responsibilities and service style of the staff.",
        question11:"Question 11: Please evaluate the convenience and usefulness of the training material.",
        question12:"Question 12: If you have any suggestions, or additional information, kindly send to us",
        nonExist:"Traning course is invalid",
        error:"Data is abnormal",
        success:"Thank you very much for your feedback!",
        submiterror:"For any information, kindly contact admin: +86 13662650451",
        nameerror:"Topic could not be empty",
        dateerror:"The date could not be empty"
    },
    menu:{
        scheduleplan:"Schedule Plan",
        calendar:"Calendar",
        order:"Orders",
        "service-order":"Service Orders",
        tool:{
            name:"Tools",
            netdisk:"Net Disk",
            traning:"Training",
            statement:"Statement",
            leave:"Leave"
        },
        statistics:{
            name:"Statistics",
            workingday:"Working Day",
        }        
    },
    actions:{
        new:"New",
        view:"View",
        edit:"Edit",
        status:"Status",
        followup:"Follow-up",
        delete:"Delete",
        actions:"Actions",
        refresh:"Refresh",
        back:"Return",
        share:"Share",
        submit:"Submit",
        search:"Search",
        download:"Download",
        close:"Close",
        copy:"Copy",
        cancel:"Cancel",
        ok:"Ok",
        add:"Add",
        createAppForm:"Create Application Form",
        downAppForm:"Download Application Form",
        send:"Send",
        createQuotation:"Create Quotation",
        createContract:"Create Contract",
        archivingContract:"Archiving Contract",
        archivingApplicationForm:"Archiving Application Form",
        invoice:"Invoice",
        downContract:"Download Contract",
        bindingClient:"Binding Client",
        newClient:"New Client",
        binding:"Binding",
        assignSales:"Assign Sales",
        assignCS:"Assign CS",
        createInvoice:"Create Invoice",
        archivingInvoice:"Archiving Invoice",
        downInvoice:"Download Invoice",    
        internalSharing:"Internal Share",
        feedback:"Feedback",
        confirm:"Confirm",
        argue:"Argue",
        upload:"Upload",
        save:"Save",
        cancelReason:"Cancel Reason"
    },
    quotation:{
        title:"Quotation",
        language:"Quotation Language Type",
        languageCn:"Chinese",
        languageEn:"English",
        currencyType:"Currency",
        personDays:"Person-Days",
        vat:"VAT",
        detail:"Details",
        total:"Total",
        add:"Add",
        subtract:"Subtract",
    },
    order:{
        country:"Country",
        pageTitle:"Orders",
        orderNo:"Order No.",
        type:"Type",
        status:"Status",
        payment:"Payments",
        invoice:"Invoice",
        source:"Source",
        applicationDate:"App-Date",
        new:"New",
        myApplicationForm:"My Application Form",
        general:"General",
        fem:"Higg FEM",
        slcp:"SLCP",
        aws:"AWS",
        zero:"SUPPLIER TO ZERO LEVEL 2 REVIEW",
        zdhcil1:"ZDHC VERIFIED INCHECK LEVEL 1",
        ghg:"GHG Inventory",
        errorNoQuotation:"No quotation found, please create one!",
        errorCreateFail:"Failed to create, please contact administrator",
        infoFileUpload:"File has been updated",
        infoAppDeleteAlert:"Delete cannot be restored",
        errorOrderExits:"Order does not exist",
        errorRequest:"Request exception, please contact administrator",
        infoConfirmNewQuotation:"Are you sure about the new quotation?",
        infoHisQuotatiton:"Doing so will invalidate historical quotations!",
        infoQuotationSent:"The quotation has been sent, please follow up!",
        errorSent:"Failed to send, please contact the administrator",
        infoSure:"Are you sure?",
        infoOperation:"The operation will not be undone",
        errorUpdate:"Update failed!", 
        infoBindAlert:"Please bind the client profile first!",
        infoQuoteAlert:"Please create a quote first!",
        infoPaymentStatus:"To mark a payment, you need to upload the payment voucher and create a service order",
        statusSettings:"Status Settings",
        paymentVoucher:"Payment Voucher",
        followupRecord:"Follow-up Records",
        infoEnterContent:"Please enter the content of the communication",
        infoCreateClient:"Create a new client profile for the order",
        infoBindOrder:"Bind the order to this client profile",
        errorBindingFail:"Binding Failed",
        infoEnterClientName:"Please enter the client name",
        infoNoRecord:"No Record",
        errorNoPaid:"The order not paid",
    },
    invoice:{
        infoUpdated:"Invoicing information has been updated",
        infoEmail:"Invoice request email has been sent, please follow up with the customer in time.",
        infoStatus:"Invoicing status has been updated",
        title:"Invoicing Information",
        sendEmail:"Send request form",
        export:"Export invoicing information",
        status:"Set Invoiced",
        loadHistory:"Load History",
        companyName:"Company Name",
        taxNumber:"Tax Number",
        address:"Address",
        phoneNumber:"Phone Number",
        bank:"Bank Name",
        accountNumber:"Account Number",
        express:"Express information",
        recipient:"Recipient",
        billNo:"Bill Number",
        errorCompanyName:"Company name cannot be empty",
        errorTaxNumber:"Tax Number cannot be empty",
        errorAddress:"Address cannot be empty",
        errorPhone:"Phone number cannot be empty",
        errorBank:"Bank name cannot be empty",
        errorAccount:"Account number cannot be empty",
        errorRecipient:"The recipient cannot be empty",
    },
    client:{
        name:"Client Name"
    },
    serviceOrder:{
        pageTitle:"Service Orders",
        serviceNo:"Service No.",
        type:"Type",
        status:"Status",
        personday:"Person-days",
        serviceDate:"Service Date",
        auditor:"Auditor",
        tabBase:"Base Information",
        tabOrder:"Order Information",
        tabPreVerified:"Pre-verified Documents",
        tabFactory:"Factory Documents",
        tabVerification:"Verification Documents",
        detail:"Detail",
        stepPS:'Pending', 
        stepSC:'Confirming', 
        stepP:'Preparing', 
        stepIP:'Verifying', 
        stepPP:'Report Writing', 
        stepPR:'Report Review', 
        stepRF:"Review Completed",  
        stepF:'Completion',
        guideline:'Guideline',
        confirmationLetter:"Confirmation Letter",
        reconfirmationLetter:"Return Confirmation Letter",
        operationLog:"Operation Log",
        gConfirmationLetter:"Generate Conf-Letter",
        sConfirmationLetter:"Send Conf-Letter",
        reviewCompletion:"Review Completion",
        sReportUpload:"Post Report",
        sPreVerified:"Pre-verified",
        sSurvey:"Survey",
        survey:"Survey",
        schedulingInfo:"Scheduling Info",
        btSchedule:"Schedule",
        contactInfo:"Contact Information",
        verificationStartDate:"Verification Start Date",
        verificationEndDate:"Verification End Date",
        announcedType:"Announced Type",
        announcedStartDate:"Announced Start Date",
        announcedEndDate:"Announced End Date",
        leadVerifier:"Lead Auditor",
        travelSchedule:"Travel Schedule",
        quantitativeMetricsVerifier:"Member Auditor",
        travelScheduleQM:"Travel Schedule(MA)",
        observer:"Observer",
        travelScheduleObserver:"Travel Schedule(Observer)",
        scheduleConfirmationDate:"Schedule Confirmation Date",
        uploadFile:"Upload documentation",
        announced:"Announced",
        semiAnnounced:"Semi-Announced",
        unannounced:"Unannounced",
        infoCompleted:"Operation will be frozen after the service order is completed",
        infoWaitingReportReviewerConfirm:"Waiting for the report reviewer to confirm the report",
        infoWorkFileUpload:"Please confirm that the work files have been uploaded",
        infoReviewRequest:"The report review request email has been sent, please wait for the assignment and promptly archive the files",
        infoStatusSet:"Status set successfully",
        infoConfirmCreateLetter:"Confirmation letter already exists, do you want to recreate it?",
        errorGenerateConfirmationLetter:"Failed to generate the confirmation letter, please contact the administrator!",
        infoConfirmationGenerated:"Confirmation letter generated successfully!",
        infoEmailSent:"Email sent, please follow up accordingly!",
        errorEmailSent:"Failed to send email, please contact the administrator.",
        infoReportReviewsuccess:"Report review settings successfully configured.",
        infoConfirmReportReview:"Please confirm that the report review conclusions have been uploaded to the work files.",
        infoReportReviewCompletion:"Report review completion notification sent.",
        errorSendReportReview:"Failed to send report review completion notification.",
        errorRetrievinTemplate:"Error retrieving email notification template.",
        confirmManday:"Please confirm workers number and verification person days comply with SLCP regulations",
        errorReview:"Please fill in the complete report review rating",
        reviewForm:"Report Review Form",
        reviewBasic:"Basic Information",
        reviewQuestion:"Rating Details" ,
        notesAnnouncedType:"Please note that the announced type for this verification is",
        assessmentId:"Assessment ID",
        checklist:"Check List",
        reportPostDeadline:"Report Post Deadline",
        scheduleSLCP:"SLCP Schedule",
        scheduleFEM:"FEM Schedule"        
    },
    netdisk:{
        pageTitle:"Net Disk",
        folder:"Folder",
        file:"File",
        name:"Name",
        size:"Size",
        owner:"Owner",
        myshare:"My Share",
        uploadfile:"Upload File",
        folderName:"Folder Name",
        fileName:"File Name",
        discription:"Discription",
        ispublic:"Is public?",
        specifiedusers:"Visible to specified users",
        shareName:"Share Name",
        shareDate:"Share Date",
        expiryDate:"Expiry Date",
        shareLink:"Share Link",
        shareType:"Share Type",
        allowUploading:"Allow Uploading",
        permanent:"Permanent",
        create:"Create",
        shareInformation:"Share Information",
        needCode:"Need verification code",
        noNeedCode:"Non-Need verification code",
        code:"Verification code",
        day:"Days",
        errorNeedCode:"The verification code cannot be empty."
    },
    email:{
        to:"To",
        cc:"CC",
        bcc:"BCC",
        subject:"Subject",
        attachment:"Attachment"
    },
    survey:{
        customerservice:"Customer Service",
        ourservice:"Our Service",
        greenpont:"GreenPont",
        suggestion:"Suggestions",
        q1:"Please rate the timeliness of service delivery (phone, e-mail and proposal submission)", 
        q2:"Please rate the clarification of doubts / claims satisfaction",   
        q3:"Please rate our technical support overall", 
        q4:"Please rate our commercial support overall",    
        q5:"Please rate the quality of our service efficiency", 
        q6:"Please rate the quality of our service communication",
        q7:"Please rate the quality of our service accuracy",
        q8:"Please rate our auditors punctuality, treatment and personal presentation ",
        q9:"How do you qualify the auditor's expertise in the industry audited? ",
        q10:"Do you consider that our evaluations were accurate, clear and organized during the auditing process? ",
        q11:"Did you receive detailed information about the audit process and results during the Opening & Closing Meetings? ",
        q12:"Were your questions answered satisfactorily? ",
        q13:"Please rate the timely delivery of the audit report",
        q14:"How likely is it that you would use our services again?",
        q15:"How do you rate the level of knowledge and skills of our auditor?",
        q16:"Would you recommend GreenPont services to a colleague or acquaintance?",
        q17:"Overall, how satisfied are you with GreenPont?",        
        formName:"Service Satisfaction Survey",
        companyName:"Company Name",
        feedback:"Thank you for your feedback",
        feedbackError:"Feedback submission error, please contact the administrator"        
    },
    workingday:{
        name:"Statistcs Working Day",
        detail:"Statistcs Detail",
        cycle:"Cycle",
        auditor:"Auditor",
        status:"Confirm Status",
        confirmDate:"Confirm Date",
        feedback:"Enter your question",
        wdVerificationDays:"Verification days on working days",
        hdVerificationDays:"Verification days on holiday",
        wdTravelDays:"Travel days on working days",
        hdTravelDays:"Travel days on holiday",
        wdObservationDays:"Observation days on working days",
        hdObservationDays:"Observation days on holiday",
        wdObservationoftd:"Observation of travel days on working days",
        hdObservationoftd:"Observation of travel days on holiday"
    },
    aws:{
        formName:"Alliance for Water Stewardship Certification",
        applicantInformation:"Applicant Information",
        facility:"Facility",
        desiredCertificationLevel:"Desired Certification Level",
        certificationType:"Certification Type",
        auditType: "Audit Type",
        factoryName:"Factory Name",
        referenceNumber:"AWS Reference Number (if any)",
        address:"Address",
        employees:"No. of employees",
        occupiedArea:"Occupied area (Unit: Square Meters) ",
        mainProducts:"Main Products /Services to be certified",
        mainManufacture:"Please describe the main manufacture/service flow processes",
        wastewater:"Would Industrial wastewater be generated during daily operation? ",
        annualWater:"Annual water consumption Amount (Unit: ton)",
        sourceWater:"What is the source for Water input(select the applicable one(s))",
        wastewaterReused:"Would any wastewater be reused or recycled onsite? <br/>If yes, please describe the reused or recycled situation:<br/>-	How many tons of wastewater monthly?<br/>-	What is the reused/recycled process?<br/>-	Where the reused/rec",
        wastewaterTreatmentPlant:"Does the facility has its own wastewater treatment plant/facility/process onsite?",
        dischargedWastewater:"Where the discharged wastewater would be channeled to? (outside the boundary of facility)",
        reason:"Reason",
        certLevError:"Please select desired certification level",
        certTypeError:"Please select certification type",
        auditTypeError:"Please select audit type",
        factoryNameError:"Please enter factory name",
        addressError:"Please enter factory address",
        employeesError:"Please enter No. of employees",
        occupiedAreaError:"Please enter occupied area",
        mainProductsError:"Please describe main products /services provided by the facility",
        mainManufactureError:"Please describe the main production processes",
        wastewaterError:"Please confirm whether the facility generates industry wastewater or not?"        
    },
    zero:{
        formName:"SUPPLIER TO ZERO LEVEL 2 REVIEW APPLICATION FORM",
        experience:"Supplier to Zero level 2 review experience",
        finishLevel1:"Is the Supplier to Zero level 1 completed",
        model:"Review mode",
        zdhcID:"ZDHC Gateway ID",
        openingHour:"Opening hour",
        fromFactoryAirport:"How far from factory is the airport/station (by car)",
        cmc:"Chemical management certificate",
        numberofChemical:"The number of chemical inventory at the facility",
        mrsl:"MRSL Conformity Certificate",
        chemicalUsed:"The quantity of chemical used",
        materials:"Materials",
        serviceDate:"Expected Review Date"
    },    
    zdhc:{
        formName:"ZDHC VERIFIED INCHECK LEVEL 1 APPLICATION FORM",
        experience:"ZDHC Verified Incheck Level 1 experience",
        performance:"Performance Incheck Report of past three months or three consecutive quarters are completed",
        model:"Type process",
        productionArea:"Production area",
        administration:"Administration",	
        chemicalStorageArea:"Chemical storage area",
        other:"Other",	
    },
    ghg:{
        formName:"GHG Inventory Application Form",
        year:"Year",
        energySource:"Energy  Source",
        multipleSite:"Multiple site",
        involvesNewEnergy:"Involves new energy",
        onsitePowerGeneration:"Onsite Power Generation",
        energySupplytoOtherSites:"Energy Supply to other sites",
        carbonOffset:"Carbon offset",
        mainProduction:"The main production equipments",
        onsiteCanteen:"Onsite canteen or dormitory",
        subcontractedProcess:"Subcontracted process?",
        millsLease:"Mills lease",
        operationFacility:"Operation facility (switch room,waste water treatment plant, air emission scrubber, air conditioners, Compressor)",
        sitEnergyMeteringMap:"Site energy metering map",
        chemicalInventory:"Chemical inventory (Specialty gases Included)",
        fireExtinguisher:"Fire extinguisher(Co2) used",
        fireSuppressionSystem:"HFC-227ea fire suppression system",
        wawHandlingProcess:"Waste water,Air emissions,waste handling process"
    },
    statement:{
        pageName:"Statement",
        name:"Statement Name",
        type:"Verify Type",
        file:"File",
        status:"Status",
        assessmentId:"Assessment ID",
        facilityName:"Facility Name",
        check:"Check",
        sync:"Sync Update",
        started:"Checking",    
        syncFinished:"Synchronization update completed",
        serviceNo:"Service No",
        index:"No.",
        add:"New Statement"
    },
    leave:{
        pagename:"Leave",
        new:"New",
        myapp:"My applications",
        todo:"My todo",
        startDate:"Start Date",        
        endDate:"End Date",
        leaveType:"Leave Type",
        status:"Status",
        newApplication:"New Application",
        dateError:"Date error",
        attachment:"Attachment",
        draft:"Save Draft",
        submitApproval:"Submit Approval",
        close:"Close",
        applicant:"Applicant",
        approver:"Approver"
    }    
}
export default en
import request from '@/request'
export const userinfo = ()=>{
    return request({
        url:'/api-gateway/service/queryUserInfo',
        method:'post'
    });
};
export const menuTree = () => {
    return request({
        url:'/api-gateway/service/queryUserMenu',
        method:'post'
    });
}
export const querySystemUser = (data) => {
    return request({
        url:`/api-gateway/service/querySystemUser/${data.pageSize}/${data.pageNum}`,
        method:'post',
        data,
    });
}
export const createUser = (data) => {
    return request({
        url:'/api-gateway/service/createUser',
        method:'post',
        data,
    });
}
export const editUser = (data) => {
    return request({
        url:'/api-gateway/service/editUser',
        method:'post',
        data,
    });
}
export const deleteUser = (data) => {
    return request({
        url:'/api-gateway/service/deleteUser',
        method:'post',
        data,
    });
}
export const checkUser = (userName) => {
    return request({
        url:'/api-gateway/service/checkUser',
        method:'get',
        params:{"userName":userName},
    });
}
export const changePassword = (data) => {
    return request({
        url:'/api-gateway/service/changePassword',
        method:'post',
        data,
    });
}
export const resetPassword = (data) => {
    return request({
        url:'/api-gateway/service/resetPassword/change',
        method:'post',
        data,
    });
}
export const forgotPassword = (data) => {
    return request({
        url:'/api-gateway/service/resetPassword/confirm',
        method:'post',
        data,
    });
}
export const checkCode = (data) => {
    return request({
        url:'/api-gateway/service/resetPassword/check',
        method:'post',
        data,
    });
}
export const getStaffDetail = ()=>{
    return request({
        url:'/api-gateway/service/staffDetail',
        method:'post'
    });
};
export const saveMyInfo = (data) => {
    return request({
        url:'/api-gateway/service/saveMyInfo',
        method:'post',
        data,
    });
}
export const queryStaffList = (data)=>{
    return request({
        url:'/api-gateway/service/queryStaffList',
        method:'post',
        data
    });
};
export const getStaffLeaveStatistics = (data)=>{
    return request({
        url:'/api-gateway/service/getStaffLeaveStatistics',
        method:'post',
        data
    });
};

